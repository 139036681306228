import React from 'react';
import { graphql } from 'gatsby';

import { Page } from '../components/layout';

import Section from './downloads/section';
import styles from './downloads.module.scss';

const Downloads = ({
  data: {
    markdownRemark: {
      frontmatter: { sections },
      fields: { slug },
      html
    }
  }
}) => {
  return (
    <Page slug={slug} body={html} showContact={false}>
      {Array.isArray(sections) &&
        sections.map(({ title, icon, files }, index) => (
          <div key={index} className={styles.section}>
            <Section icon={icon} files={files} title={title} />
          </div>
        ))}
    </Page>
  );
};

export const downloadsQuery = graphql`
  query downloadsQuery($remarkId: String!) {
    markdownRemark(id: { eq: $remarkId }) {
      frontmatter {
        sections {
          title
          icon {
            publicURL
          }
          files {
            name
            file {
              publicURL
            }
          }
        }
      }
      fields {
        slug
      }
      html
    }
  }
`;

export default Downloads;
