import React from 'react';
import PropTypes from 'prop-types';

import { SVG } from '../../components/icons';

import styles from './section.module.scss';

const Section = ({ title, files, icon: { publicURL: src } }) => (
	<div className={styles.wrapper}>
		<div className={styles.icon}>
			<SVG path={src} className={styles.svg} />
		</div>
		<div className={styles.text}>
			<h2 className={styles.title}>{title}</h2>
			{Array.isArray(files) && (
				<ul>
					{files.map(({ name, file: { publicURL: src } }, index) => (
						<li key={index}>
							<a href={src} target='_blank'>
								{name}
							</a>
						</li>
					))}
				</ul>
			)}
		</div>
	</div>
);

Section.defaultProps = {
	title: null,
	icon: null,
	files: []
};

Section.propTypes = {
	title: PropTypes.string.isRequired,
	icon: PropTypes.shape({
		publicURL: PropTypes.string.isRequired
	}).isRequired,
	files: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string.isRequired,
			file: PropTypes.shape({
				publicURL: PropTypes.string.isRequired
			}).isRequired
		}).isRequired
	).isRequired
};

export default Section;
